<template>
  <div>
    <base-header base-title="Antrian Pasien"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <auto-refresh
                :table-name="tableName"
                :on-refresh="fetch"
              ></auto-refresh>
              <template v-if="checkPermission('QueueResource.POST.Queue.create')">
                &nbsp;&nbsp;
                <base-button-modal
                  button-text="Tambah Antrian"
                  button-icon="flaticon2-add-1"
                  button-name="modal_queue_user_auto"
                  :onButtonModalClicked="showModalAuto"
                ></base-button-modal>
              </template>
              <template v-if="checkPermission('QueueResource.POST.Queue')">
                &nbsp;&nbsp;
                <base-button-modal
                  button-text="Tambah Antrian Manual"
                  button-icon="flaticon2-add-1"
                  button-name="modal_queue_user"
                  :onButtonModalClicked="showModal"
                ></base-button-modal>
              </template>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-inline">
                <div class="form-group mb-2">
                  <div class="input-group input-group-sm date">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la la-calendar-o glyphicon-th"></i>
                      </span>
                    </div>
                    <date-range-picker
                      ref="filterRangeVue"
                      :changedValueStart.sync="dateStart"
                      :changedValueEnd.sync="dateEnd"
                      id-date-picker="filter_rangedatepicker"
                      picker-value
                      place-holder="DD/MM/YYYY - DD/MM/YYYY"
                    ></date-range-picker>
                  </div>
                </div>
                <button
                  type="button"
                  @click="submitSearch()"
                  class="btn btn-sm btn-default btn-bold btn-upper mb-2 ml-2"
                >
                  <i class="flaticon2-search"></i> {{ $t("button.search") }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="showLoading"
            class="m-auto p-5 d-flex flex-row h-100"
          >
            <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
            <h5 class="my-auto pl-5">Loading ...</h5>
          </div>
          <div v-if="isLoaded">
            <data-tables :table="table"></data-tables>
          </div>
        </div>
        <form-modal
          v-if="isShowModal"
          :show-modal.sync="isShowModal"
          @event="fetch"
          :modal-name="modalName"
          :modal-data="dataEdit"
        ></form-modal>
        <form-modal-auto
          v-if="isShowModalAuto"
          :show-modal.sync="isShowModalAuto"
          @event="fetch"
          :modal-name="modalAutoName"
        ></form-modal-auto>
        <form-forward-target
          v-if="isShowModalForward"
          :show-modal.sync="isShowModalForward"
          @event="fetch"
          :modal-name="modalForwardTarget"
          :modal-data="dataEditForward"
        ></form-forward-target>
        <modal-printer
            v-if="showModalPrinter"
            :payload="payloadPrint"
            @print="print"
        ></modal-printer>
        <DivToPrint
            :detailPrint="detailPrint"
        ></DivToPrint>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import { Printd } from "printd";
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "./../_general/AutoRefresh";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import BaseButtonModal from "./../_base/BaseButtonModal";
import DateRangePicker from "./../_general/DateRangePicker";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const QueueUserRepository = RepositoryFactory.get("queueUser");
const ServerTimestampRepository = RepositoryFactory.get("serverTimestamp");

import DivToPrint from '../_general/DivToPrint.vue'
import qz from "qz-tray";
import { mapState, mapActions, mapMutations } from 'vuex';
const { RSAKey, KJUR, KEYUTIL, stob64, hextorstr } = require('jsrsasign');

export default {
  components: {
    AutoRefresh,
    DataTables,
    BaseTitle,
    BaseHeader,
    BaseButtonModal,
    DateRangePicker,
    DivToPrint,
    FormModal: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-queue" */ "./_components/FormModal.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormModalAuto: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-queue-auto" */ "./_components/FormModalAuto.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormForwardTarget: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-queue-forward" */ "./_components/FormForwardTarget.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    ModalPrinter: () => ({
        component: new Promise(function (resolve, reject) {
            setTimeout(() => {
                resolve(import(/* webpackChunkName: "modal-printer" */ "../_select/SelectPrinter.vue"))
            }, 1000)
        })
    }),
  },
  data() {
    var vx = this;
    return {
      isLoaded: false,
      showLoading: true,
      isShowModal: false,
      isShowModalAuto: false,
      isShowModalForward: false,
      dataEdit: null,
      dataEditForward: null,
      dateStart: null,
      dateEnd: null,
      headTitle: "Antrian Pasien",
      tableName: "table_queue_user",
      modalName: "modal_queue_user",
      modalAutoName: "modal_queue_user_auto",
      modalForwardTarget: "modal_forward_target",
      table: {
        order: [[1, "desc"], [2, "desc"]],
        tableName: "table_queue_user",
        tableDataResult: [],
        tableColumns: [
          {
            title: "ID",
            data: "id"
          },
          {
            title: "Tanggal Kedatangan",
            data: "date"
          },
          {
            title: "Waktu Dibuat",
            data: "createdDate"
          },
          {
            title: "No Antrian",
            data: "queueNoFormatted"
          },
          {
            title: "Display Name 1",
            data: function(row, type) {
              if(row.queueUnit) {
                if(row.queueUnit.displayName1){
                  return row.queueUnit.displayName1
                }
              }
              return ' '
            }
          },
          {
            title: "Display Name 2",
            data: function(row, type) {
              if(row.queueUnit) {
                if(row.queueUnit.displayName2){
                  return row.queueUnit.displayName2
                }
              }
              return ' '
            }
          },
          {
            title: "Tipe Antrian",
            data: "queueType"
          },
          {
            title: "Status",
            data: "queueStatus"
          },
          {
            title: "Actions",
            data: "id",
            createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
              let btnAction = Vue.extend(BtnAction);
              let instance = new btnAction({
                propsData: {
                  queueUserVue: vx,
                  rowData: rowData
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          }
        ],
        tableColumnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: 1,
            render: function (t, e, a, n) {
              var momentDate = moment(t);
              return momentDate.format("DD/MM/YYYY");
            }
          },
          {
            targets: 2,
            render: function (t, e, a, n) {
              var momentDate = moment(t);
              return momentDate.format("DD/MM/YYYY HH:mm:ss");
            }
          },
          {
            targets: 6,
            render: function (t, e, a, n) {
              var i = {
                AUTO: {
                  title: "Auto",
                  state: "info"
                },
                MANUAL: { title: "Manual", state: "primary" }
              };
              return void 0 === i[t]
                ? t
                : '<span class="kt-badge kt-badge--' +
                i[t].state +
                ' kt-badge--dot"></span>&nbsp;' +
                '<span class="kt-font-bold kt-font-' +
                i[t].state +
                '">' +
                i[t].title +
                "</span>";
            }
          },
          {
            targets: 7,
            className: "text-center",
            render: function (t, e, a, n) {
              var i = {
                NEW: {
                  title: "New",
                  class: "kt-badge--brand"
                },
                CHECKEDIN: {
                  title: "CheckedIn",
                  class: "kt-badge--brand"
                },
                SKIPPED: {
                  title: "Skipped",
                  class: "kt-badge--warning"
                },
                CALLING: {
                  title: "Calling",
                  class: "kt-badge--info"
                },
                SERVING: {
                  title: "Serving",
                  class: "kt-badge--metal"
                },
                DONE: {
                  title: "Done",
                  class: "kt-badge--success"
                },
                CANCEL: {
                  title: "Cancel",
                  class: "kt-badge--danger"
                }
              };
              return void 0 === i[t]
                ? t
                : '<span class="kt-badge ' +
                i[t].class +
                ' kt-badge--inline kt-badge--pill">' +
                i[t].title +
                "</span>";
            }
          },
          {
            targets: -1,
            responsivePriority: 1
          }
        ],
        tableFilter: [
          {
            title: "Tanggal Kedatangan",
            type: "datepicker"
          },
          {
            title: "Waktu Dibuat",
            type: "datepicker"
          },
          {
            title: "No Antrian",
            type: "text"
          },
          {
            title: "Display Name 1",
            type: "combobox"
          },
          {
            title: "Display Name 2",
            type: "combobox"
          },
          {
            title: "Tipe Antrian",
            type: "combobox"
          },
          {
            title: "Status",
            type: "combobox"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ]
      },
        detailPrint: {
            displayName1: "",
            displayName2: "",
            schedule: "",
            queueNo: "",
            date: "",
            content: "empty"
        },
        qrProps: {
            margin: 0,
            width: 80,
            errorCorrectionLevel: 'M',
            quality: 1
        },
        payloadPrint: {
            printD: false,
            queueId: null
        }
    };
  },
    computed: {
    ...mapState('CallerStore', {
        showModalPrinter: state => state.showModalPrinter,
        defaultPrinter: state => state.defaultPrinter,
        isLoading: state => state.isLoading
    }),
  },
  watch: {
    isShowModal: function (val) {
      if (!val) {
        this.dataEdit = null;
      }
    },
    isShowModalForward: function (val) {
      if (!val) {
        this.dataEditForward = null;
      }
    }
  },
  methods: {
    ...mapMutations({
        setListPrinter: "CallerStore/SET_LIST_PRINTER",
        setDefaultPrinter: "CallerStore/SET_DEFAULT_PRINTER",
        setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
        setLoading: "CallerStore/SET_LOADING",
    }),
    showModal: function () {
      this.isShowModal = true;
    },
    showModalAuto: function () {
      this.isShowModalAuto = true;
    },
    showModalForward: function () {
      this.isShowModalForward = true;
    },
    submitSearch: function () {
      this.showLoading = true;
      this.isLoaded = false;
      this.fetch();
    },
    async fetch(resolve) {
      const params = {
        "date.greaterOrEqualThan": this.dateStart,
        "date.lessOrEqualThan": this.dateEnd
      }
      new Promise((resolve, reject) => {
        QueueUserRepository.get(params, resolve, reject);
      }).then(response => {
        const data = response.data;
        if (this.isLoaded) {
          var datatable = $("#" + this.tableName).DataTable();
          datatable.clear();
          datatable.rows.add(data);
          datatable.draw("full-hold");
          if (resolve !== undefined) {
            resolve(data);
          }
        } else {
          this.table.tableDataResult = data;
          this.showLoading = false;
          this.isLoaded = true;
        }
      }).catch(error => {
        var msg = (error.message) ? error.message : error;
        this.showToastError('Queue User : ' + msg);
      })
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function (message) {
      toastr.error(message);
    },
    showModalSelectPrinter(payload){
        let vx = this;
        vx.payloadPrint = payload
        vx.setShowModalPrinter(true)
    },
    async print({ printD, queueId }) {
      let vx = this;
        if (queueId) {
            await QueueUserRepository.getQueueUser(queueId)
            .then(async response => {
                vx.detailPrint.displayName1 = response.data.queueUnit.displayName1;
                vx.detailPrint.displayName2 = response.data.queueUnit.displayName2;
                if(response.data.queueUnit.jadwal){
                    vx.detailPrint.schedule =
                    "(" +
                    helpers.daysToId(response.data.queueUnit.jadwal.hari) +
                    ", " +
                    response.data.queueUnit.jadwal.jamMulai +
                    " - " +
                    response.data.queueUnit.jadwal.jamSelesai +
                    ")";
                }
                vx.detailPrint.queueNo = response.data.queueNoFormatted;
                vx.detailPrint.content = `{"content": "${response.data.encryptedId}","type":"antrian"}`

            await ServerTimestampRepository.get()
                .then(response => {
                  var dateFormattedId = moment(response.data).format(
                    "dddd, D MMMM YYYY HH:mm:ss"
                    );
                    vx.detailPrint.date = dateFormattedId;
                })

            const printDiv = document.getElementById("divToPrint") 
            if(printD){
                const d = new Printd();
                await d.print(printDiv);
            } else {

            var config = qz.configs.create(vx.defaultPrinter);
            // The QR data
            var qr = vx.detailPrint.content
            // The dot size of the QR code
            var dots = "\x04";
            // Some proprietary size calculation
            var qrLength = qr.length + 3;
            var size1 =  String.fromCharCode(qrLength % 256);
            var size0 = String.fromCharCode(Math.floor(qrLength / 256));
            let tenantPrinter = localStorage.getItem('tenantPrinter')

            const el = document.getElementById('printQueue')
            // console.log(el);
            const options = {
                type: "dataURL",
                scale: 1
            };
            this.output = await this.$html2canvas(el, options);
            
                var data = [
                    '\x1B' + '\x40',	//init
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x45' + '\x0D', // bold on
                    '\x1B' + '\x21' + '\x30', // em mode on
                    `${tenantPrinter !== undefined ? tenantPrinter : 'RS MANDAYA'  }`,
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x1B' + '\x45' + '\x0A', // bold off
                    '\x0A' + '\x0A',
                    `${vx.detailPrint.displayName1}`,
                    '\x0A',
                    `${vx.detailPrint.displayName2}`,
                    '\x0A' + '\x0A',
                     {
                      type: 'raw', format: 'image', flavor: 'base64',
                      data: this.output, 
                        options: { language: "ESCPOS"},
                    },
                    `${vx.detailPrint.date} `,
                   '\x0A',
                    'Mohon menunggu nomor Anda dipanggil',
                    '\x0A' + '\x0A', //new lines
                                          // <!-- BEGIN QR DATA -->
                    '\x1D' + '\x28' + '\x6B' + '\x04' + '\x00' + '\x31' + '\x41' + '\x32' + '\x00',    // <Function 165> select the model (model 2 is widely supported)
                    '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x43' + dots,               // <Function 167> set the size of the module
                    '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x45' + '\x30',             // <Function 169> select level of error correction (48,49,50,51) printer-dependent
                    '\x1D' + '\x28' + '\x6B' + size1 + size0 + '\x31' + '\x50' + '\x30' + qr,          // <Function 080> send your data (testing 123) to the image storage area in the printer
                    '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x51' +'\x30',              // <Function 081> print the symbol data in the symbol storage area
                    '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x52' +'\x30',              // <Function 082> Transmit the size information of the symbol data in the symbol storage area
                    // <!-- END QR DATA -->
                    '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    'Scan QR ini dengan Aplikasi Care Dokter',
                    '\x0A',
                    'Download di Google Play Store & Apple App Store',
                    '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',              // more line feeds and text to see if we messed up the QR Code syntax      
                    '\x1D' + '\x56'  + '\x01' //CUT
                ];
                try{
                    await qz.print(config, data).catch(function(e) { console.error(e); });
                }catch(err){
                    console.log(err);
                }
            }
          })
          .catch(error => { });
      }
    },
    async initPrinter(){
        let vx = this
        // ini daftarinnya qz sertifikat biar prompt hanya untuk pertama kali
        qz.security.setCertificatePromise(function (resolve, reject) {
            resolve("-----BEGIN CERTIFICATE-----\n" +
                    "MIIENTCCAx2gAwIBAgIJALSDkOzWjaVnMA0GCSqGSIb3DQEBCwUAMIGvMQswCQYDVQQGEwJJRDESMBAGA1UECAwJSW5kb25lc2lhMRAwDgYDVQQHDAdKYWthcnRhMR8wHQYDVQQKDBZQVCBNYW5kYXlhIFNlaGF0IFV0YW1hMQwwCgYDVQQLDANNU1UxHDAaBgNVBAMMEyoubWFuZGF5YWhlYWx0aC5jb20xLTArBgkqhkiG9w0BCQEWHm1hbmRheWFpdEBtYW5kYXlhbWVkaWNhbC5ncm91cDAgFw0yMDA3MDYwNjIxNTFaGA8yMDUxMTIzMDA2MjE1MVowga8xCzAJBgNVBAYTAklEMRIwEAYDVQQIDAlJbmRvbmVzaWExEDAOBgNVBAcMB0pha2FydGExHzAdBgNVBAoMFlBUIE1hbmRheWEgU2VoYXQgVXRhbWExDDAKBgNVBAsMA01TVTEcMBoGA1UEAwwTKi5tYW5kYXlhaGVhbHRoLmNvbTEtMCsGCSqGSIb3DQEJARYebWFuZGF5YWl0QG1hbmRheWFtZWRpY2FsLmdyb3VwMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt4kSToo9ub62omhfTutrI1xmx8z4/od5RPd98pREQC6f8Hl5S6MZ3HfhcgtvWBNQzLY2vm2vdKnGUXmrA5DcI49ldE9zjud55hv9s3ZpCOlgCCiaAmk5xa8k16RMxm+CoBLLFPfqQ3Z9LwXjqcYkLK45ya99wXDy3zhv1D3rcqcEk2W4oies0JtjptRv3uk3xyglFhblNRM9asrp5SPWVbyBFQLPBjBD3TtPzt9qF1sLXsMw80ykh8vovUhiLrQ+177J7wJ6dIueQ/pOC2QvFYyNLoCBi8h+q9x+eLjVH84q3lNf9uWOa/X30wqRAz9vgGxtbdZDEL5i/ZhWspAI2wIDAQABo1AwTjAdBgNVHQ4EFgQUUJEnwO4gFLIBIi5R7RxNou033WswHwYDVR0jBBgwFoAUUJEnwO4gFLIBIi5R7RxNou033WswDAYDVR0TBAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAZfoTSLT5PO6ZK67JBkXUIwnGRcy04pQZ6hTvfH974vh6vVRsM5FZUw8lsY5A0WRHLve9jodcl8iWJbNWf5/54lYfLxNAEon0BnygioqWHCtppIlFuMg0btvzZ1Qa2pUyUezc0I3Z9XThCVfSsCJIIZd0NVvOuLrlhjTqh+AuRWLEgfBgRc2tbrmIOiNcnwnvBVcbI/aq/pSYL6tDZXWh4ykBb0m/adiudrQofTyS9VaT0Oz0D0r1ktiExsdmhIrrtOD8fKg8LKQzFMfEybQtuJpMWqDZ6EAzo4YXkCZyroANTp5ADOa5eUtttNcQSo6FlfF4iO0kzdNAHuQq859WWw==\n" +
                    "-----END CERTIFICATE-----");
        });

        var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
                "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC3iRJOij25vraiaF9O62sjXGbHzPj+h3lE933ylERALp/weXlLoxncd+FyC29YE1DMtja+ba90qcZReasDkNwjj2V0T3OO53nmG/2zdmkI6WAIKJoCaTnFryTXpEzGb4KgEssU9+pDdn0vBeOpxiQsrjnJr33BcPLfOG/UPetypwSTZbiiJ6zQm2Om1G/e6TfHKCUWFuU1Ez1qyunlI9ZVvIEVAs8GMEPdO0/O32oXWwtewzDzTKSHy+i9SGIutD7XvsnvAnp0i55D+k4LZC8VjI0ugIGLyH6r3H54uNUfzireU1/25Y5r9ffTCpEDP2+AbG1t1kMQvmL9mFaykAjbAgMBAAECggEAEDZGbLOfYjHrdgscKB95dkUqOwnrEKis2J+3+16HbNfxfs9sHHdKyIO6YVWLP09tere5vDZ6IicBAp9psMXx/B4lixGdlojx5pagYWLbQlNDDVVwzTe4R7MB4R59PaYJ9tPSJocDpXPo+OdfRdbfySaWqi8bP6QXgAo/LpNNofHijF1iiHCGdCYWwUJrvKHTjxDVfPmXT8AZPre24Y1c96AraBiVFVA9go/gDG6rdlLW/3rkb6A/Hj/txESX2KXLXSwpysKnw3uwPZwo+wHWyZO6VRHOqjc7Xdr7A0ugzAzmYidIUquRE6edM7ek7WvzvpgoYqHRy5Il8xNptHvosQKBgQDiMX288l9DVjo+n2vFs6E3KLFmqr2NMeGDjBW0zdMZcHOHVUgdXObpX+HHogmjvlqn9QcfcEe0St3JRXTCOnJbR3ZRdKaRqj5kvQHaugXxJBA7f4QoAeGhBbqg52sXMmgZGTafaQgcoibeMKZ2CTAnxz0rgLIIXpS4hVqj+PZr+QKBgQDPuIomJbhGmflcPx7b0+sVfxfdEtA+WUakptAzT3ddYIZxRMsMUhEcdIhV+ESOGQseuPDUJzqXPeamEg5vTKwyluSejmp6t09+A3s7gKYzLdNaAZX87+0TSUBLwZDHLxTb58wGvHrL/bHgOEP4ylmFbs7PiKkYzgZl6n5+JlvIcwKBgGfT9fa8ftQHCbdjMHJQ0UjjigMpjtb4NZ9Pt0sGS8daof1WPUMVOP2ZQKeFjs01/KEqTXm17YQp9YKFOmJJt+KEfCBJ3ofw0LEnPWmsiDu3pSfakcxXiL2ERrXDZJoCTlxvAW7NJKcYG6VUSaTW9N7diwukCJTk7XmB5ShtiJfpAoGAOo9gAxknNmA6OJNi875uzASkiaLAKpOoosqUb6K7mAuaW1x1keehA1CnDvWK/jR5OhQRcP4OOo0s9Syp/R60fR6lHT4xLp93PaNlnoTKVitZJkrEKibJw2InSjQryLuxOb3DezKvu8qt1vSF1SHW0xNGIqP4aBHNI/ZT6uSNg5kCgYEA1jGaGLAz+6o+t65RwWdEgnt+bRl1mHdvpp1+H/AtWhtPGJm6EnU4myFyZjpRNYXIvk6JRX89nwuxdUyZAztEvh3t24ENEBEbBp1n7qFDSXoIicD0mSywZHnzceleD3NPk9jx2V5ztZDinaHbdkXypYr1PkeWNYwfzh5CqzyDWLE=\n" +
                "-----END PRIVATE KEY-----";
        function strip(key) {
            if (key.indexOf('-----') !== -1) {
                return key.split('-----')[2].replace(/\r?\n|\r/g, '');
            }
        }
        
        qz.security.setSignaturePromise(function (toSign) {
            return function (resolve, reject) {
                try {
                    var pk = KEYUTIL.getKey(privateKey);
                    var sig = new KJUR.crypto.Signature({"alg": "SHA1withRSA"});
                    sig.init(pk)
                    sig.updateString(toSign)
                    var hex = sig.sign()
                    resolve(stob64(hextorstr(hex)));
                } catch (err) {
                    console.error(err);
                    reject(err);
                }
            };
        });

        const options = {
            host: 'localhost',
            port: {
                insecure: [8182]
            },
            usingSecure: false
        }
        
      if(!qz.websocket.isActive()){
        await qz.websocket.connect(options)
            .then(() => {
                return qz.printers.find()
            })
            .then((data) => {
                if(data) {
                    localStorage.printerList = JSON.stringify(data)
                } else {
                    toastr.error("Can't find Printer")
                }
            })
      }
    },
  },
  mounted() {
    this.$refs.filterRangeVue.getDatePicker();
    this.fetch();
    this.initPrinter()
    if(localStorage.defaultPrinter){
      this.setDefaultPrinter(localStorage.defaultPrinter)
    }
  }
};
</script>

<template>
  <div class="text-center">
    <template v-if="checkPermission('QueueResource.PUT.Queue')">
      <button
        @click="editQueueUser()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template>
      <button
        @click="printQueueUser()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Print Ticket"
      >
        <i class="la la-print"></i>
      </button>
    </template>
    <!-- <div
      class="printTicket"
      :id="'printTicket_' + rowData.id"
    >
      <div style="text-align:center; margin: 0 6pt 0 6pt; font-family: sans-serif; height: 1000px; min">
        <div style="font-size: 14px;">MANDAYA HOSPITAL KARAWANG</div>
        <br />
        <div style="font-size: 12px;">{{ detailPrint.displayName1 }}</div>
        <div style="font-size: 12px;">{{ detailPrint.displayName2 }}</div>
        <div style="font-size: 12px;">{{ detailPrint.schedule }}</div>
        <div style="font-size: 55px;font-weight: bold;">{{ detailPrint.queueNo }}</div>
        <br />
        <div style="font-size: 12px;">{{ detailPrint.date }}</div>
        <div style="font-size: 12px;">Mohon menunggu nomor Anda dipanggil.</div>
        <br />
        <br />
        <div>.</div>
      </div> -->
    <!-- </div> -->
    <template v-if="checkPermission('QueueResource.POST.Queue.forward')">
      <button
        @click="forwardQueueUser()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Forward"
      >
        <i class="la la-share-square-o"></i>
      </button>
    </template>
    <template v-if="checkPermission('QueueResource.DELETE.Queue.id')">
      <button
        @click="deleteQueueUser()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import { Printd } from "printd";
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const QueueUserRepository = RepositoryFactory.get("queueUser");
const ServerTimestampRepository = RepositoryFactory.get("serverTimestamp");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  data() {
      var vx = this;
    return {
      detailPrint: {
        displayName1: "",
        displayName2: "",
        schedule: "",
        queueNo: "",
        date: ""
      },
      payloadPrint: {
          queueId: vx.rowData.id,
          printD: false
      }
    };
  },
  props: {
    queueUserVue: { type: Object },
    rowData: { type: Object }
  },
  methods: {
    deleteQueueUser() {
      var vx = this;

      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Data Antrian Pasien akan terhapus.",
        swalType: "warning",
        onConfirmButton: function () {
          blockUI.blockPage();
          vx.delete();
        },
        onCancelButton: function () {
          blockUI.unblockPage();
        }
      });
    },
    async delete() {
      var vx = this;

      await QueueUserRepository.deleteQueueUser(this.rowData.id)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.queueUserVue.fetch(resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Antrian Pasien berhasil terhapus.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Antrian Pasien gagal terhapus.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockPage();
            }
          });
        });
    },
    editQueueUser() {
      this.queueUserVue.dataEdit = this.rowData;
      this.queueUserVue.showModal();
    },
    async printQueueUser() {
        var  vx = this;
        await helpers.checkPrinter()
            .then(data => {
                if(data.selectPrinter){
                    vx.queueUserVue.showModalSelectPrinter(vx.payloadPrint)
                } else {
                    vx.queueUserVue.print(vx.payloadPrint)
                }
            })
            .catch(err => {
                vx.payloadPrint.printD = true
                vx.queueUserVue.print(vx.payloadPrint)
            })
    },
    forwardQueueUser() {
      this.queueUserVue.dataEditForward = this.rowData;
      this.queueUserVue.showModalForward();
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  }
};
</script>